/*
*****************************
**                         **
**  Default website fonts  **
**                         **
*****************************
*/

@font-face {
    font-family: "Camphor Thin";
    font-weight: 200;
    src: url("https://cdn.poirot.app/fonts/Camphor-200-thin.woff")
        format("woff");
}

@font-face {
    font-family: "Camphor Light";
    font-weight: 300;
    src: url("https://cdn.poirot.app/fonts/Camphor-300-light.woff")
        format("woff");
}

@font-face {
    font-family: "Camphor Regular";
    font-weight: 400;
    src: url("https://cdn.poirot.app/fonts/Camphor-400-regular.woff")
        format("woff");
}

@font-face {
    font-family: "Camphor Medium";
    font-weight: 500;
    src: url("https://cdn.poirot.app/fonts/Camphor-500-medium.woff")
        format("woff");
}

@font-face {
    font-family: "Camphor Bold";
    font-weight: 600;
    src: url("https://cdn.poirot.app/fonts/Camphor-600-bold.woff")
        format("woff");
}

@font-face {
    font-family: "Camphor Heavy";
    font-weight: 700;
    src: url("https://cdn.poirot.app/fonts/Camphor-700-heavy.woff")
        format("woff");
}

@font-face {
    font-family: "Digital Display";
    font-weight: 400;
    src: url("https://cdn.poirot.app/fonts/Digital-Display.woff")
        format("woff");
}

@font-face {
    font-family: "Lucida Console";
    font-weight: 400;
    src: url("https://cdn.poirot.app/fonts/Lucida-Console.woff")
        format("woff");
}

/*
*************************
**                     **
**  Default HTML tags  **
**                     **
*************************
*/

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%;
    margin: 0;
    padding: 0;
}

html, body {
    overflow: hidden;
}

body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    margin: 0;
    padding: 0;
}

body {
    background: #ffffff;
    color: #343f44;
    min-height: 100%;
    height: 100%;
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

body::-webkit-scrollbar {
    display: none;
}

menu {
    /*padding-inline-start: 0;*/
}

ul {
    list-style: none;
    white-space:nowrap;
}

li {
    display: inline-block;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 500;
    list-style: none;
    margin: 0 10px;
    word-break: keep-all;
    white-space: nowrap;
}

a {
    text-decoration: none;
    color: #0070c9;
}

a:hover {
    cursor: pointer;
    text-decoration: underline;
}

p {
    letter-spacing: 0.025em;
    line-height: 22px;
}

button {
    border-width: 1px;
    border-style: solid;
    color: #ffffff;
    display: inline-block;
    font-family: inherit;
    height: 32px;
    line-height: 32px;
    outline: none;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    padding: 0 15px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.025em;
}

button:hover {
    text-decoration: none;
    cursor: pointer;
}

button:focus {
    outline: none;
}

button:focus[data-focus-method="mouse"]:not(input):not(textarea):not(select),
button:focus[data-focus-method="touch"]:not(input):not(textarea):not(select) {
    -webkit-box-shadow: none;
    box-shadow: none;
}

button:active {
    outline: none;
}

a,
button,
input,
select,
textarea {
    -webkit-tap-highlight-color: transparent;
}

input {
    border: 1px solid #dedede;
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    padding: 10px 20px;
}

input[type="checkbox"]:hover {
    cursor: pointer;
}

#root {
    min-height: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    display: grid;
    position: relative;
}

/*.noselect {
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;  
    -ms-user-select: none;
    user-select: none; 
}*/

.grayedOut * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    pointer-events: none;
    color: #dedede;
    fill: #f2f2f2;
    cursor: not-allowed;
}

.grayedOut * button {
    opacity: 0.2;
}


/*
************************
**                    **
**  Section wrappers  **
**                    **
************************
*/

.fullScreenWrapper {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
}

.fixedWidthWrapper {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
}

.authenticationSectionWrapper {
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;

    background: url(../assets/img/gradient2.png) bottom no-repeat;
    background-size: cover;
}

/*
*****************************
**                         **
**  User defined elements  **
**                         **
*****************************
*/

.defaultButtonBlue {
    background-color: #0070c9;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#42a1ec),
        to(#0070c9)
    );
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    border-color: #0070c9;
}

.defaultButtonBlue:hover {
    background-color: #147bcd;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#51a9ee),
        to(#147bcd)
    );
    background: -webkit-linear-gradient(#51a9ee, #147bcd);
    background: linear-gradient(#51a9ee, #147bcd);
    border-color: #147bcd;
}

.defaultButtonBlue:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(131, 192, 253, 0.5);
    box-shadow: 0 0 0 3px rgba(131, 192, 253, 0.5);
}

.defaultButtonBlue:active {
    background-color: #0067b9;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#3d94d9),
        to(#0067b9)
    );
    background: -webkit-linear-gradient(#3d94d9, #0067b9);
    background: linear-gradient(#3d94d9, #0067b9);
    border-color: #006dbc;
}

.defaultButtonGreen {
    background-color: #83a333;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#99bf38),
        to(#83a333)
    );
    background: -webkit-linear-gradient(#99bf38, #83a333);
    background: linear-gradient(#99bf38, #83a333);
    border-color: #83a333;
}

.defaultButtonGreen:hover {
    background-color: #97ae37;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#a8c73a),
        to(#97ae37)
    );
    background: -webkit-linear-gradient(#a8c73a, #97ae37);
    background: linear-gradient(#a8c73a, #97ae37);
    border-color: #97ae37;
}

.defaultButtonGreen:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(218, 222, 53, 0.5);
    box-shadow: 0 0 0 3px rgba(218, 222, 53, 0.5);
}

.defaultButtonGreen:active {
    background-color: #839a23;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#94b225),
        to(#839a23)
    );
    background: -webkit-linear-gradient(#94b225, #839a23);
    background: linear-gradient(#94b225, #839a23);
    border-color: #839a23;
}

.defaultButtonRed {
    background-color: #C91B14;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#ED5E5A),
        to(#C91B14)
    );
    background: -webkit-linear-gradient(#ED5E5A, #C91B14);
    background: linear-gradient(#ED5E5A, #C91B14);
    border-color: #C91B14;
}

.defaultButtonRed:hover {
    background-color: #CC2F29;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#ED6C68),
        to(#CC2F29)
    );
    background: -webkit-linear-gradient(#ED6C68, #CC2F29);
    background: linear-gradient(#ED6C68, #CC2F29);
    border-color: #CC2F29;
}

.defaultButtonRed:focus {
    -webkit-box-shadow: 0 0 0 3px rgba(242, 158, 155, 0.5);
    box-shadow: 0 0 0 3px rgba(242, 158, 155, 0.5);
}

.defaultButtonRed:active {
    background-color: #BA1913;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#D95752),
        to(#BA1913)
    );
    background: -webkit-linear-gradient(#D95752, #BA1913);
    background: linear-gradient(#D95752, #BA1913);
    border-color: #BD1613;
}

.authenticationSectionHeader {
    padding: 10px;
}

.logoWrapper img {
    height: 36px;
    width: auto;
}

.signInFormWrapper,
.registerFormWrapper,
.resetPasswordWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.authenticationSectionFooter {
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.2);
    align-items: center;
}

.authenticationSectionFooter > div {
    margin: 0 10px;
}

.authenticationSectionFooter > div > p,
.authenticationSectionFooter > div > a {
    color: rgba(255, 255, 255, 0.7);
}

.authenticationForm {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    max-width: 480px;
    width: 100%;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
}

.authenticationForm > div:nth-child(1) {
    background-color: #f2f2f2;
    border-bottom: 1px solid #dedede;
    border-radius: 10px 10px 0 0;
    flex: 0;
    padding: 4%;
}

.authenticationForm > div:nth-child(2) {
    text-align: center;
    padding: 6% 0 0 0;
}

.authenticationForm > div:nth-child(2) > div:not(:first-child) {
    margin: 6% 0;
}

.resetPasswordWrapper > .authenticationForm > div:nth-child(2) {
    margin: 4% 0;
}

.authenticationForm > div:nth-child(2) > div:last-child {
    margin: 0 0 6% 0;
}

.authenticationForm > div:nth-child(3) {
    background-color: #f2f2f2;
    border-top: 1px solid #dedede;
    border-radius: 0 0 10px 10px;
    flex: 0;
    text-align: right;
    padding: 2%;
}

.authenticationForm > div > h1 {
    font-size: 28px;
    font-weight: 400;
    text-align: center;
}

.authenticationForm > div > div h2 {
    font-weight: 400;
    font-size: 18px;
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
}

.authenticationForm > div > div input[type="email"],
.authenticationForm > div > div input[type="password"],
.authenticationForm > div > div input[type="text"] {
    max-width: 80%;
    width: 100%;
    font-family: Camphor, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    border: 1px solid #dedede;
    padding: 0 20px;
    height: 42px;
    border-radius: 4px;
}

.authenticationFormCheckboxWrapper {
    padding: 0 10%;
    text-align: justify;
}

.authenticationFormCheckboxWrapper > label {
    margin: 0 10px;
}

.authenticationFormCheckboxWrapper > label:hover {
    cursor: pointer;
}

.authenticationFormCheckboxWrapper > p {
    color: #a8b6be;
    font-size: 12px;
    margin-top: 3%;
    line-height: 17px;
}

.authenticationForm > div hr {
    height: 1px;
    border: 0;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(
        left,
        #ffffff 0%,
        #dedede 50%,
        #ffffff 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        #ffffff 0%,
        #dedede 50%,
        #ffffff 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #ffffff 0%,
        #dedede 50%,
        #ffffff 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}

.authenticationForm > .resetPasswordButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.authenticationFormTfa {
    text-align: center;
    max-width: 80%;
    width: 100%;
}

.honeyPot {
    display: none;
}

/*
*********************************
**                             **
**  Loading Animation Spinner  **
**                             **
*********************************
*/

.loaderAnimationWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 80px;
    border-radius: 10px;
    flex-direction: column;
}

.fullScreenLoaderWrapper > .loaderAnimationWrapper {
    background-color: rgba(0,0,0,0.8);
}

.fullScreenLoaderWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
}

.singleComponent .fullScreenLoaderWrapper {
    width: 100%;
    height: 100%;
}

.singleComponent .loaderAnimationWrapper {
    background: none;
    padding: 0;
    border-radius: 0;
}

.singleComponent .loaderAnimationMessage {
    display: none;
}

.singleComponent .loaderAnimation div:after {
    background: #343f44;
}

.loaderAnimationMessage {
    color: #ffffff;
    margin-top: 30px;
    opacity: 0.9;
    text-align: center;
    max-width: 280px;
}

.loaderAnimation {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
}

.loaderAnimation div {
    transform-origin: 12px 12px;
    animation: loaderAnimation 1.2s linear infinite;
}

.loaderAnimation div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 1px;
    left: 11px;
    width: 2px;
    height: 6px;
    border-radius: 20%;
    background: #fff;
    opacity: 0.9;
}

.loaderAnimation div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.loaderAnimation div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.loaderAnimation div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.loaderAnimation div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.loaderAnimation div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.loaderAnimation div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.loaderAnimation div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.loaderAnimation div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.loaderAnimation div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.loaderAnimation div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.loaderAnimation div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.loaderAnimation div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

.resetPasswordWrapper > .loaderAnimation div:after {
    background: #fff;
}

@keyframes loaderAnimation {
    0% {
        opacity: 0.9;
    }
    100% {
        opacity: 0;
    }
}

.displayNone {
    display: none;
}

/*
****************************
**                        **
**  Application elements  **
**                        **
****************************
*/

.unavailableNotification {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.unavailableNotification > div > h1 {
    padding: 20px 0;
}

.App {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-height: 100vh;
    height: 100%;
}

h1 {
    font-family: Camphor Thin, Open Sans, Segoe UI, sans-serif;
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 20px;
}

h2 {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 22px;
    font-weight: 600;
}

h3 {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

h4 {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 500;
}

h5 {
    font-family: Camphor Light, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 300;
}

h6 {
    font-family: Camphor Thin, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 200;
}

.header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.logoHeader {
    background: #FFFFFF;
    height: 50px;
    width: 100%;
    z-index: 9;
}

.header .logoWrapper {
    padding: 7px 0 3px 0;
    flex: 0;
}

.logoHeaderContent {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
}

.gravatarWrapper {
    align-items: center;
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: center;
    word-break: keep-all;
    white-space: nowrap;
}

.gravatarWrapper > div {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0 8px;

    border-radius: 12px;
    padding: 4px 12px;
}

.gravatarWrapper > div > span {
    margin: 0 0 0 8px;
}

.gravatarWrapper > div:hover, .workspaceSelectionVisible {
background-color: #83a333;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#99bf38),
        to(#83a333)
    );
    background: -webkit-linear-gradient(#99bf38, #83a333);
    background: linear-gradient(#99bf38, #83a333);
    cursor: pointer;
    color: #ffffff;
}

.gravatarWrapper > div:hover .caretDown, .workspaceSelectionVisible .caretDown {
    transform: rotate(90deg) scale(0.8);
}

.gravatarWrapper > div:hover .caretDown g, .workspaceSelectionVisible .caretDown g {
    stroke: #ffffff;
}

.gravatarImage {
    border-radius: 50%;
    height: 38px;
    width: 38px;
    border: 1px solid #dedede;
}

.gravatarWrapper .caretDown {
    margin: 0;
    transition: all 0.3s ease-in-out; 
    
}

.modeSelectorWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
}

.modeSelector ul li {
    border-radius: 12px;
    padding: 4px 12px;
}

.modeSelector ul li:hover {
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    color: #FFFFFF;
    cursor: pointer;
}

.modeSelector .activeMode {
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    color: #FFFFFF;
}

.modeSelector .activeMode:hover {
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    color: #FFFFFF;
}

.modeSelector .disabled {
    pointer-events:none; 
    opacity:0.3;         
}

.workspaceSelectorWrapper {
    border-radius: 4px;
    border: 1px solid #dedede;
    background: #FFFFFF;
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 10;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    max-width: 680px;
    min-width: 250px;
    opacity: 0;
    transition: all 0.3s ease-in-out; 
}

.workspaceSelector h1 {
    padding: 8px 20px;
    text-align: center;
    margin: 8px 0 0 0;
}

.workspaceSelector ul {
    list-style: none;
    white-space:nowrap;
    margin: 0;
}

.workspaceSelector ul li {
    display: block;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 15px;
    font-weight: 500;
    list-style: none;
    word-break: keep-all;
    white-space: nowrap;
    margin: 0;
    padding: 8px 30px 8px 30px;
    height: 46px;
    display: flex;
    align-items: center;
}

.workspaceSelector ul li:hover {
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    color: #FFFFFF;
    cursor: pointer;
}

.workspaceSelector .disabled {
    pointer-events:none; 
    opacity:0.3;     
    padding: 20px 30px;    
}

.workspaceSelector > ul > hr {
    height: 1px;
    border: 0;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left, #ffffff 0%, #dedede 50%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffffff 0%,#dedede 50%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffffff 0%,#dedede 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    margin: 8px 0;
}

.workspaceSelectorItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.workspaceSelectorItem > div {
    display: flex;
    align-items: center;
}

.workspaceSelectorItem > div:first-child {
    margin-right: 20px;
}

.workspaceSelectorItem .teamAvatarImage {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    object-fit: cover;
    border: none;
}

.WorkspaceChangeNotification {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 10;
    display: none;
    transition: all 0.3s ease-in-out;
}

.WorkspaceChangeNotification > h1 {
    color: #fff;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 63px;
    font-weight: 600;
}


.breadcrumbsHeader {
    background: #212425;
    color: #fff;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 13px;
    font-weight: 600;
    height: 50px;
    z-index: 9;
}

.breadcrumbs {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.breadcrumbs img {
    margin-right: 18px;
}

.breadcrumbs > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.breadcrumbs .chevronRight {
    padding: 0 18px;
}

.breadcrumbsContent {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0 20px;
}

.secondaryNavigationWrapper {
    background: #f2f2f2;
    border-bottom: 1px solid #dedede;
    z-index: 9;
}

.secondaryNavigation {
    padding: 5px 20px;
}

.secondaryNavigationItem {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: color .1s;
    color: #343f44;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: color .1s;
    white-space: nowrap;
    margin: 0 25px 0 0;
}

.secondaryNavigation ul li:hover {
    color: #0070c9;
    cursor: pointer;
    text-decoration: underline;
}

.active {
    color: #0070c9;
    cursor: pointer;
    text-decoration: underline;
}

.cycleSwitch {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.cycleSwitch > div {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
}
.cycleSwitch > div:nth-child(2) {
    margin: 0 10px;
}

.cycleSwitch > div h3 {
    margin: 0;
    padding: 0;
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 60px;
    height: 30px;
    background: #dedede;
    border-radius: 60px;
    position: relative;
    transition: background-color .3s;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    border-radius: 26px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-checkbox:checked + .react-switch-label {
    background-color: #83a333;
    border-color: #83a333;
}

.toggleLabel:hover {
    cursor: pointer;
}

.contentBlockWrapper {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.content {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
}

.emailFinderWrapper, .domainSearchWrapper, .emailVerificationWrapper, .contactListsWrapper, .availableTeamsWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.emailFinder, .domainSearch, .emailVerification, .contactLists, .availableTeams {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.emailFinder .requestForm, .domainSearch .requestForm, .emailVerification .requestForm {
    background: #f2f2f2;
    border-bottom: 1px solid #dedede;
    padding: 20px;
}

.emailFinder .requestForm > div {
    display: grid;
    grid-template-columns: 4fr 20px 4fr 2fr;
    grid-column-gap: 20px;
}
.emailFinder .requestForm > div h1:nth-child(1) {
    grid-column: 1 / 3;
}
.emailFinder .requestForm > div h1:nth-child(2) {
    grid-column: 3 / 5;
}

.emailFinder .requestForm form {
    display: grid;
    grid-template-columns: 4fr 20px 4fr 2fr;
    grid-column-gap: 20px;
}

.emailFinder .requestForm form > span {
    align-items: center;
    display: flex;
    padding: 0;
    justify-content: center;
}

.emailFinder .requestForm form > div, .domainSearch .requestForm form > div, .emailVerification .requestForm form > div {
    align-items: center;
    display: flex;
    justify-content: center;
}

.domainSearch .requestForm > div, .emailVerification .requestForm > div {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    grid-column-gap: 20px;
}

.domainSearch .requestForm form, .emailVerification .requestForm form {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    grid-column-gap: 20px;
}

.domainSearch .requestForm form > input, .emailVerification .requestForm form > input {
    grid-column-start: 2;
}

.domainSearch .requestForm > div h1, .emailVerification .requestForm > div h1 {
    grid-column: 2 / 5;
}



.helperPanel {
    background: #f2f2f2;
    border-left: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    flex: 0;
    min-width: 320px;
}

.helperPanelTabs {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
}

.helperPanelTab, .helperPanelTabUnselected {
    background: #f2f2f2;
    border: none;
    border-radius: 0;
    color: #343f44;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 13px;
    font-weight: 600;
    min-width: 160px;
    height: 40px;
    line-height: 40px;
}

.helperPanelTab:hover {
    cursor: default
}

.helperPanelTabUnselected {
    background: #dedede;
}

.helperPanelTabUnselected:hover {
    text-decoration: underline;
}

.helperPanelTabs > .helperPanelTab:nth-child(1), .helperPanelTabs > .helperPanelTabUnselected:nth-child(1) {
    border-bottom-right-radius: 4px;
}

.helperPanelTabs > .helperPanelTab:nth-child(2), .helperPanelTabs > .helperPanelTabUnselected:nth-child(2) {
    border-bottom-left-radius: 4px;
}

.helperPanelTabs > h1 {
    font-size: 24px;
    padding: 20px 10px;
    margin: 0 auto;
}
 
.helperPanelDataWrapper {
    display: flex;
    flex: 1;
    overflow-y: scroll;
}


.domainDataWrapper {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.detailsViewStamp {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
}

.detailsViewStamp.singleComponent {
    opacity: 1;
}

.domainDataLogo {
    /*max-width: 100%;*/
    flex: 0 0 auto;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.domainDataLogo img {
    max-width: 100%;
    max-height: 120px;
    /*width: auto;*/
}

.domainDataWrapper > div {
    flex: 0 0 auto;
}

.domainDataName, .domainDataTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 30px;
}

.domainDataName h3 {
    font-size: 18px;
}

.domainDataTitle h4 {
    font-size: 16px;
}

.domainDataURL {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
}

.domainDataURL > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.domainDataURL > div > div {
    padding: 0 6px;
}

.domainDataURL > div > div:first-child {
    flex: 0;
    text-align: right;
}

.domainDataURL > div > div:first-child > span {
    background: #dedede;
    padding: 2px 12px;
    border-radius: 12px;
}

.domainDataURL > div:first-child {
    margin-bottom: 12px;
}

.domainDataURL > div > div:last-child {
    flex: 1;
    text-align: left;
}

.domainDataMailServer, .domainDataMailboxProvider, .domainDataMailboxCatchAll, .domainDataRecordsNumber {
    padding: 0 30px 20px 30px;
    text-align: center;
    font-size: 14px;
}

.domainDataMailServer span, .domainDataMailboxProvider span {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 13px;
    font-weight: 600;
}

.domainDataMailboxCatchAll > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.domainDataMailboxCatchAll > div > .verificationStatusPass {
    /*color: #a4d037;*/
    margin-left: 8px;
    /*background-color: red;*/
}

.domainDataMailboxCatchAll svg {
    width: 16px;
    height: auto;
}

.domainDataMailboxCatchAll > div > .verificationStatusFail {
    /*color: #f05230;*/
    margin-left: 8px;
    /*background-color: red;*/
}

.domainDataRecordsNumber span {
    font-family: "Digital Display", Open Sans, Segoe UI, sans-serif;
    font-size: 20px;
}






.bulkActionsWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.bulkActionsStoryWrapper {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    padding: 60px;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    background-color: #f2f2f2;
    border-right: 1px solid #dedede;
}

.bulkActionsStoryWrapper::-webkit-scrollbar {
    display: none;
}

.bulkActionsStory {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 40px 60px;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    max-width: 680px;
}

.bulkActionsStory > div > h1 {
    text-align: center;
    font-size: 28px;
}

.bulkActionsStory > div > h2 {
    font-size: 22px;
    margin: 20px 0 15px 0;
}

.bulkActionsStory > div {
    text-align: center;
}

.bulkActionsStory > div > img {
    padding: 20px;
}

.bulkActionsStory > div > p {
    text-align: justify;
    margin: 10px 0;
}

.bulkActionArea {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 40px;
}

.dropZone {
    align-items: center;
    background: #f2f2f2;
    border: 2px dashed #dedede;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 75px;
    text-align: center;
}

.dropZone > div:first-child {
    padding: 0 0 40px 0;
}

.dropZone > div > svg {
    fill: #dedede;
}

.dropZone > div > h2 {
    font-size: 18px;
    margin: 0 0 10px 0;
}

.dropZone > div > h4 {
    font-size: 16px;
}

.dropZone > div > h4 > span {
    color: #0070c9;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
}

.dropZone > div > h4 > span:hover {
    cursor: pointer;
    text-decoration: underline;
}

.teamworkWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.teamwork {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.newTeamCreationWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.newTeamCreationWrapper h1 {
    font-size: 30px;
}

.initialTeamCreationForm {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr 3fr;
    grid-column-gap: 20px;
    margin: 40px 0;
}

.initialTeamCreationForm input {
    grid-column-start: 2;   
}

.initialTeamCreationForm button {
    grid-column-start: 3;
    align-self: center;
}

.teamsListWrapper {
    flex: 1 0 auto;
    max-width: 50vw;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.teamsListWrapper::-webkit-scrollbar {
    display: none;
}


.teamsList, .teamAdditionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 15px 0 15px;
}

.teamWrapper {
    align-items: center;
    border-radius: 4px;
    padding: 8px 20px;
    display: grid;
    grid-template-columns: 1fr 6fr 1fr 2fr 2fr;
    grid-column-gap: 10px;
    border: 1px solid #fff;
    margin-bottom: 10px;
    width: 100%;
}

.teamWrapper:hover {
    border: 1px dashed #A4D037;
    cursor: pointer;
}

.inFocus {
    border: 1px dashed #A4D037;
    background: rgba(164, 208, 55, 0.1);
}

.inFocus:hover {
    cursor: default;
}

.noHover:hover {
    cursor: default;
    border: 1px solid #ffffff;
}

.teamMembers, .teamStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f2f2f2;
    border-radius: 20px;
    padding: 4px 10px;
    margin: 4px 0;
}

.teamMembers > div, .teamStatus > div {
    display: flex;
    align-items: center;
}

.teamStatus .checkCircle {
    width: 16px;
    height: auto;
}

.teamStatus .timesCircle {
    width: 16px;
    height: auto;
}

.teamEditIconsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
}

.editIcon:hover {
    cursor: pointer;
}

.editIcon:hover > svg path {
    fill: #000000;
}

.teamRemovalWrapper, .teamMemberRemovalWrapper, .paymentMethodRemovalWrapper, .downgradeFormWrapper, .accountCreditFormWrapper, .referralEmailInviteWrapper {
    align-items: center;
    background: rgba(0,0,0,0.4);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 10;
    word-break: normal;
    white-space: normal;
}

.teamRemoval, .teamMemberRemoval, .paymentMethodRemoval, .downgradeForm, .accountCreditForm {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    max-width: 480px;
    padding: 4%;
    width: 100%;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
}

.teamRemoval, .teamMemberRemoval, .paymentMethodRemoval, .downgradeForm, .accountCreditForm {
    text-align: center;
}

.teamRemoval > h1, .teamMemberRemoval > h1, .paymentMethodRemoval > h1, .downgradeForm > h1, .accountCreditForm > h1 {
    font-size: 30px;
    margin-bottom: 35px;
}

.teamRemoval > h4, .downgradeForm > h4 {
    color: #f05230;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    margin: 20px 0 40px 0;
}

.accountCreditForm p:nth-child(3) {
    margin: 0 0 40px 0;
}

.teamRemoval > p, .teamMemberRemoval > p, .paymentMethodRemoval > p, .downgradeForm > p, .accountCreditForm > p {
    margin: 0 0 15px 0;
}

.teamRemoval > p span, .teamMemberRemoval > p span, .paymentMethodRemoval > p span, .downgradeForm > p span, .accountCreditForm > p span {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.teamRemovalButtonWrapper, .teamMemberRemovalButtonWrapper, .paymentMethodRemovalButtonWrapper, .downgradeFormButtonWrapper, .accountCreditFormButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.teamRemovalButtonWrapper button, .teamMemberRemovalButtonWrapper button, .paymentMethodRemovalButtonWrapper button, .downgradeFormButtonWrapper button, .accountCreditFormButtonWrapper button {
    margin: 0 10px;
    width: 140px;
}

.teamMemberRemovalButtonWrapper, .paymentMethodRemovalButtonWrapper {
    margin: 40px 0 0 0;
}

.teamAddition {
    align-items: center;
    border-radius: 4px;
    padding: 0;
    width: 100%;
    margin-bottom: 30px;
}

.addTeamAvatar {
    width: 38px;
    height: auto;
}

.teamAddition > button {
    margin: 14px 0;
}

.teamInputWrapper {
    width: 100%;
    align-items: center;
    background: #f2f2f2;
    border-radius: 4px;
    padding: 8px 20px;
    display: grid;
    grid-template-columns: 1fr 9fr 1fr 1fr;
    grid-column-gap: 10px;
    border: 1px solid #dedede;
    margin-bottom: 10px;
}

.teamInputWrapper img:hover {
    cursor: pointer;
    opacity: 0.8;
}

.teamName > input[type="text"] {
    width: 100%;
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 8px 10px;
    margin: 3px 0;
}

.cancelButton {
    background: #FFF;
    color: #c0c0c0;
}

.cancelButton:hover {
    background: #c0c0c0;
    color: #FFF;
}

input[type="file"] {
    display: none;
}

.teamAvatarImage {
    border-radius: 50%;
    height: 38px;
    width: 38px;
    object-fit: cover;
    border: 1px solid #dedede;
}

.breadcrumbsTeamAvatarImage {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    object-fit: cover;
    border: none;
}

.teamAvatarImageWrapper {
    position: relative;
}

.removeTeamAvatarButton {
    position: absolute;
    left: 28px;
    top: -6px;
}

.removeTeamAvatarButton .timesCircle {
    width: 16px;
    height: auto;
}

.removeTeamAvatarButton .timesCircle:hover {
    cursor: pointer;
    opacity: 0.9;
}










.teamPanelWrapper {
    background: #f2f2f2;
    border-left: 1px solid #dedede;
    flex: 1 0 auto;
    max-width: 50vw;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.teamPanelWrapper::-webkit-scrollbar {
    display: none;
}

.teamPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 0;
}

.teamPanel h2 {
    margin: 0 0 15px 0;
}

.teamPanelTeamName {
    text-align: center;
    border-bottom: 1px solid #dedede;
    padding: 0 15px;
}

.teamPanelActivitySwitch {
    text-align: center;
    border-bottom: 1px solid #dedede;
    padding: 20px 15px;
}

.teamPanelTeamMembers {
    border-bottom: 1px solid #dedede;
    padding: 20px 15px;
}

.teamMember {
    display: grid;
    grid-template-columns: 2fr 6fr 8fr 2fr 1fr;
    grid-column-gap: 5px;
    align-items: center;
    white-space: nowrap;
    padding: 5px 0;
}

.teamMember >div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.teamMember:last-child {
    padding-bottom: 0;
}

.teamMember > div:last-child {
    align-items: center;
    display: flex;
    justify-content: center;
}

.teamMember svg {
    height: 16px;
}

.memberStatusActive {
    background-color: #A4D037;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: 2px 10px;
    border-radius: 11px;
    color: #fff;
}

.memberStatusPending {
    background-color: #f05230;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 2px 10px;
    border-radius: 11px;
    color: #fff;
}

.teamPanelSendInvitation {
    border-bottom: 1px solid #dedede;
    padding: 20px 15px;
}

.teamMemberInvite > form {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.teamMemberInvite > form > input {
    flex: 1;
    margin-right: 15px;
}

.teamPanelUsageLimitations {
    border-bottom: 1px solid #dedede;
    padding: 20px 15px;
}

.teamLimitations {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
}

.creditsLimit {
    background: #dedede;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 10px;
}

.creditsLimit > h6 {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.creditsLimit > input {
    text-align: right;
    background: #dedede;
    font-family: "Digital Display", Open Sans, Segoe UI, sans-serif;
    font-size: 42px;
    line-height: 42px;
    padding: 0;
}

.accountPlansWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.accountPlans {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.currentAccountPlanWrapper {
    background: #f2f2f2;
    border-right: 1px solid #dedede;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    padding: 60px;
    overflow: hidden;
}

.currentAccountPlan {
    display: flex;
    flex-direction: column;
}

.currentAccountPlan h1 {
    margin-bottom: 50px;
    text-align: center;
}

.currentAccountPlanFeatures {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    padding: 25px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 285px;
    word-break: keep-all;
    white-space: nowrap;
    flex: 1 0 auto;
}

.currentAccountPlanFeatures > .providerImage {
    align-self: center;
}

.currentAccountPlanFeatures > div > img {
    max-height: 32px;
    max-width: 100%;
}

.currentAccountPlanFeatures .planPrice {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 28px;
    font-weight: 500;
}

.currentAccountPlanFeatures .planPrice > span {
    font-family: Camphor Thin, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 200;
}

.currentAccountPlanFeatures .creditsFeature {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.currentAccountPlanFeatures .boldFeature {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    justify-content: space-between;
    margin: 8px 40px 8px 0;
}

.currentAccountPlanFeatures .boldFeature > div:nth-child(2) {
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
}

.currentAccountPlanFeatures .imageFeature {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f2f2f2;
    border-radius: 20px;
    padding: 4px 10px;
    margin: 8px 0;
}

.currentAccountPlanFeatures .imageFeature .checkCircle {
    width: 16px;
    height: auto;
}

.currentAccountPlanFeatures .imageFeature .timesCircle {
    width: 16px;
    height: auto;
}

.currentAccountPlanFeatures .imageFeature > div {
    display: flex;
    align-items: center;
}

.currentAccountPlanFeatures > div > hr {
    height: 1px;
    border: 0;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left, #ffffff 0%, #dedede 50%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffffff 0%,#dedede 50%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffffff 0%,#dedede 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    margin: 12px 0;
}

.planSelectionWrapper {
    display: flex;
    flex: 1;
}

.planSelectionWrapper .availablePlansWrapper {
    padding: 30px;
}

.planSelectionWrapper .purchaseCreditsBlock {
    padding: 30px 30px 30px 0;
}

.planSelection {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.planSelection::-webkit-scrollbar {
    display: none;
}

.planSelection > div > hr {
    height: 1px;
    border: 0;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left, #ffffff 0%, #dedede 25%, #dedede 75%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffffff 0%, #dedede 25%, #dedede 75%, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffffff 0%, #dedede 25%, #dedede 75%, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}

.upgradeSelectorWrapper {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 10px;
}

.upgradeSelectorWrapper > .upgradeNotificationWrapper > h3 {
    padding: 15px 0;
}

.upgradeNotificationWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.upgradeSelectorWrapper > .upgradeNotificationWrapper > p {
    max-width: 75%;
    width: 75%;
    text-align: center;
}

.upgradeSelector {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.upgradeTable {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    width: 100%;
    margin-top: 30px;
}

.upgradePlanFeatures {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    word-break: keep-all;
    white-space: nowrap;
}

.upgradePlanFeatures .planPrice {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 26px;
    font-weight: 500;
}

.upgradePlanFeatures .planPrice > span {
    font-family: Camphor Thin, Open Sans, Segoe UI, sans-serif;
    font-size: 16px;
    font-weight: 200;
}

.upgradePlanFeatures > div > button {
    margin: 10px 0 10px 0;
    width: 100%;
}

.upgradePlanFeatures .creditsFeature {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.upgradePlanFeatures .boldFeature {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    justify-content: space-between;
    margin: 8px 20px 8px 0;
}

.upgradePlanFeatures .boldFeature > div:nth-child(2) {
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
}

.upgradePlanFeatures .imageFeature {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f2f2f2;
    border-radius: 20px;
    padding: 4px 10px;
    margin: 8px 0;
}

.upgradePlanFeatures .imageFeature .checkCircle {
    width: 16px;
    height: auto;
}

.upgradePlanFeatures .imageFeature .timesCircle {
    width: 16px;
    height: auto;
}

.upgradePlanFeatures .imageFeature > div {
    display: flex;
    align-items: center;
}

.upgradePlanFeatures > div > hr {
    height: 1px;
    border: 0;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left, #ffffff 0%, #dedede 50%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffffff 0%,#dedede 50%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffffff 0%,#dedede 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    margin: 12px 0;
}

.upgradeSelector .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 60px;
    height: 30px;
    background-color: #83a333;
    background: -webkit-gradient(linear, left top, left bottom, from(#99bf38), to(#83a333));
    background: -webkit-linear-gradient(#99bf38, #83a333);
    background: linear-gradient(#99bf38, #83a333);
    border-color: #83a333;
    border-radius: 60px;
    position: relative;
    transition: background-color .3s;
}

.upgradeSelector .react-switch-checkbox:checked + .upgradeSelector .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.purchaseCreditsBlock {
    word-break: keep-all;
    white-space: nowrap;
    margin-left: 30px;
}

.purchaseCreditsWrapper {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 2fr;
    grid-template-rows: auto auto;
    grid-column-gap: 20px;
}

.purchaseCreditsSliderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0 0 0;
    margin-right: 10px;
}

.purchaseCreditsWrapper .chartName {
    align-self: flex-start;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.sliderRoot {
}

.sliderMark {
    display: none;
}

.sliderRail {
    color: #dedede;
    height: 6px  !important;
    border-radius: 3px !important;
    opacity: 1 !important;
}

.sliderTrack {
    background: #0070c9; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #0070c9 0%, #42a1ec 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #0070c9 0%,#42a1ec 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #0070c9 0%,#42a1ec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0070c9', endColorstr='#42a1ec',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    height: 6px  !important;
    border-radius: 3px !important;
}

.sliderThumb {
    width: 16px !important;
    height: 16px !important;
    background: #fff !important;
    border: 3px solid #42a1ec;
}

.sliderThumb:hover {
    -webkit-box-shadow: 0 0 0 8px rgba(131, 192, 253, 0.5) !important;
    box-shadow: 0 0 0 8px rgba(131, 192, 253, 0.5) !important;
    -moz-box-shadow: 0 0 0 8px rgba(131, 192, 253, 0.5) !important;
}

.sliderThumbActive {
    -webkit-box-shadow: 0 0 0 8px rgba(131, 192, 253, 0.5) !important;
    box-shadow: 0 0 0 8px rgba(131, 192, 253, 0.5) !important;
    -moz-box-shadow: 0 0 0 8px rgba(131, 192, 253, 0.5) !important;
}

.purchaseCreditsChartWrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    background: #f2f2f2;
    border-radius: 4px;
    padding: 10px 10px 2px 10px;
}
.purchaseCreditsChartWrapper .chartName {
    align-self: flex-start;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 15px;
    font-weight: 600;
}
.purchaseCreditsChartStatus {
    font-family: "Digital Display", Open Sans, Segoe UI, sans-serif;
    font-size: 60px;
    line-height: 60px;
}

.purchaseCreditsChartWrapper .kIdentificator {
    font-size: 16px;
    line-height: 16px;
}

.purchaseCreditsPricingWrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 2px 10px;
}

.purchaseCreditsPricingWrapper .chartName {
    align-self: flex-start;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 15px;
    font-weight: 600;
    flex: 1;
}

.purchaseCreditsPricing {
    font-family: Camphor Thin, Open Sans, Segoe UI, sans-serif;
    font-size: 42px;
    line-height: 42px;
    font-weight: 200;
    padding-bottom: 5px;
}

.purchaseCreditsPricing > span {
    font-family: Camphor Thin, Open Sans, Segoe UI, sans-serif;
    font-size: 22px;
    line-height: 22px;
    font-weight: 200;
}

.purchaseCreditsButton {
    grid-column: span 2 / 5;
    padding: 20px 0 0 0;
    display: flex;
    justify-content: flex-end;
}

.purchaseCreditsButton > button {
    width: 220px;
}

.profileWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.profile {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.profile::-webkit-scrollbar {
    display: none;
}

.profileBlock {
    flex: 1 0 auto;
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 1080px;
}

.profileBlock:first-child {
    margin-top: 30px;
}

.profileBlock:last-child {
    margin-bottom: 30px;
}

.profileData {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 153px;
}

.profileData > div > h1 {
    margin-bottom: 5px;
}

.personalSettingsWrapper {
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: row;
}

.personalSettingsWrapper > .settingsGravatarWrapper {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 180px;
    width: 100%;
    height: 100%;
}

.settingsGravatarWrapper > .gravatarImage {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: 1px solid #dedede;
}

.personalSettingsWrapper > .personalSettingsData {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 30px 0 20px;
}

.personalSettingsData >  div > .nameInput {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 22px;
    font-weight: 600;
    -webkit-text-fill-color:#343f44; /* Override iOS / Android font color change */
    -webkit-opacity:1; /* Override iOS opacity change affecting text & background color */
    color:#343f44;
    margin-bottom: 10px;
    width: 100%;
    padding: 5px 20px;
}

.personalSettingsData > div > .emailInput {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 500;
    -webkit-text-fill-color:#343f44; /* Override iOS / Android font color change */
    -webkit-opacity:1; /* Override iOS opacity change affecting text & background color */
    color:#343f44;
    margin-bottom: 10px;
    width: 100%;
    padding: 5px 20px;
}

.personalSettingsData > div > input:disabled {
    border: 1px solid rgba(0,0,0,0);
    -webkit-text-fill-color:#343f44; /* Override iOS / Android font color change */
    -webkit-opacity:1; /* Override iOS opacity change affecting text & background color */
    color:#343f44;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.personalSettingsData > div:last-child {
    padding: 0 20px;
    margin-top: 10px;
    color: #0070c9;
}

.personalSettingsWrapper > .editProfileButtonWrapper {
    align-items: flex-end;
    display: flex;
    flex: 0;
    height: 100%;
}

.personalSettingsWrapper > .editProfileButtonWrapper > .cancelButton {
    margin-left: 10px;
}

.twoFactorAuth {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 171px;
}

.grayedOut > .twoFactorAuth {
    height: 92px;
}

.activated2FA {
    height: 80px;
}

.activated2FA h3 {
    margin-top: 20px;
}

.grayedOut .tfaEnablerWrapper {
    align-items: flex-start;
}

.twoFactorAuth > div > h1 {
    margin-bottom: 5px;
}

.twoFactorAuth .textWrapper p {
    margin-bottom: 20px;
}

.tfaEnablerWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.tfaEnablerWrapper .lockImageWrapper {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    height: 100%;
    max-width: 180px;
    width: 100%;
    justify-content: center;
}

.tfaEnablerWrapper .lockImageWrapper > svg {
    fill: #0070c9;
}

.tfaEnablerWrapper .textWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
    padding: 0 50px;    
}

.tfaEnablerWrapper .textWrapper > p {
    margin-bottom: 20px;
    max-width: 90%;
}

.tfaEnablerWrapper .tfaActionButtonWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.tfaEnablerWrapper .tfaActionButtonWrapper > .cancelButton {
    margin-left: 10px;
}

.phoneVerificationWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.react-tel-input .special-label {
    display: none;
}

.tfaVerificationCodeWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tfaVerificationCode {
    /*margin-top: 20px;*/
}

.tfaVerificationCode > input {
    text-align: center;
    padding: 13px 0;
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-size: 16px;
}









.resetPassword {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 88px;
}

.passwordChangeWrapper {
    flex: 1;
}

.passwordChangeWrapper > form {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.passwordChangeWrapper > form > div > h2 {
    color: #dedede;
}

.passwordChangeWrapper > form > div {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
}

.passwordChangeWrapper > form > div > input {
    flex: 1;
    margin-right: 20px;
}

.passwordChangeWrapper > form > div:last-child {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex: 1;
}

.passwordChangeWrapper > form > div > .cancelButton {
    margin-left: 10px;
}







.deactivateAccount {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 79px;
}

.deactivateAccountActionWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
}







.emailPreferences {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 168px;
}

.emailPreferences > div > h3 {
    margin-bottom: 15px;
}

.emailPreferencesWrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.emailPreferencesWrapper > div > input {
   margin: 10px 0;
}

.emailPreferencesWrapper > div > label {
    margin-left: 10px;
}

.emailPreferencesWrapper > div:nth-child(3) {
    background: #f2f2f2;
    border-radius: 4px;
    padding: 10px;
}

.emailPreferencesWrapper > div > h5 {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
}

.emailPreferencesbuttonWrapper {
    align-items: flex-end;
    display: flex;
}

.emailPreferencesWrapper label:hover {
    cursor: pointer;
}

.singleComponent {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
}





.apiCode {
    display: flex;
    flex: 1;
    flex-direction: column;
    /*height: 226px;*/
}

.apiCode > div {
    flex: 1 0 auto;
}

.grayedOut > .apiCode {
    height: 92px;    
}

.apiCode > div > h1 {
    margin-bottom: 5px;
}

.apiCodeRegenerateWrapperEndpoint {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
}

.apiCodeRegenerateWrapperEndpoint > div {
    padding: 0 100px;
    text-align: center;
}

.apiCodeRegenerateWrapperEndpoint > div > h3 {
    max-width: 70%;
    margin: 20px auto;
}

.apiCodeRegenerateWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
}

.apiCodeRegenerateWrapper > div {
    padding: 0 100px;
    text-align: center;
}

.apiCodeRegenerateWrapper > div > h5 {
    max-width: 70%;
    margin: 20px auto;
}

.apiCodeRegenerateAction {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.apiCodeRegenerateActionEndpoint {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: row;
}

.apiCodeRegenerateActionEndpoint > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.apiCodeRegenerateActionEndpoint > div:first-child {
    margin-right: 10px;
    flex: 1 0 auto;
}

.apiCodeRegenerateActionEndpointButton {
    padding: 5px 0;
}

.apiCodeRegenerateActionEndpointButton > button:nth-child(2) {
    margin-left: 10px;
}

.personalAccessToken {
    background: #f2f2f2;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 8px 60px;
    flex: 0 1 auto;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-size: 14px;
}

.clickable:hover {
    cursor: pointer;
}

.personalAccessToken .tokenKey {
    font-family: monospace;
    -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.apiCodeRegenerateActionButtons {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.apiCodeRegenerateActionButtons > button:nth-child(2) {
    margin-left: 10px;
}

.personalAccessTokenEndpoint {
    background: #f2f2f2;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 8px 60px;
    flex: 0 1 auto;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-size: 14px;
}

.apiCodeRegenerateActionEndpointInput > input {
    flex: 1 0 auto;
}


.teamMembership {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.teamMembershipWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #f2f2f2;
    border-radius: 4px;
    padding: 10px 0;
}

.profileTeamWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
}

.profileTeamWrapper:not(:last-child) {
    margin-bottom: 10px;
}

.profileTeamWrapper > div:nth-child(1) {
    display: flex;
    padding: 8px 30px;
    justify-content: center;
    align-items: center;
}


.profileTeamWrapper > div:nth-child(2) {
    flex: 1;
}

.profileTeamWrapper > div:nth-child(3) {
    padding: 0 30px;
}

.teamMembership h3 {
    margin: 0 auto;
}

.profileTeamImage {
    flex: 0 1 auto;
    padding: 0 30px 0 0;
}

.profileTeamImage img {
    border-radius: 50%;
    height: 38px;
    width: 38px;
    object-fit: cover;
    border: 1px solid #dedede;
}

.profileTeamWrapper > div > div:hover {
    cursor: pointer;
}

.profileTeamWrapper > div > div:hover > svg > path {
    fill: #000000;
}










.emailConfirmationWrapper {
    align-items: center;
    background: rgba(0,0,0,0.4);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 10;
}

.emailConfirmation {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    border: 2px solid #dedede;
    border-radius: 10px;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    width: 900px;
    height: 480px;
    overflow: hidden;
    text-align: center;
}

.emailConfirmationActionAreaWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.emailConfirmationActionAreaWrapper > div {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emailConfirmationActionAreaWrapper > div > h1 {
    font-size: 32px;
    padding: 40px 60px 0 60px;
    margin: 0;
}
.emailConfirmationActionAreaWrapper > div {
    display: flex;
    flex-direction: column;
}

.emailConfirmationActionAreaWrapper > div > div {
    flex: 1 0 auto;
}
.emailConfirmationActionAreaWrapper > div > div > span {
    background: #f2f2f2;
    border-radius: 4px;
    padding: 8px 20px;
    width: 100%;
}

.emailConfirmationActionAreaWrapper > div:nth-child(2) {
    padding: 0 60px;
}

.emailConfirmationActionAreaWrapper > div:nth-child(3) {
    padding: 0 60px;
}

.emailConfirmationActionAreaWrapper > div:last-child {
    flex: 0 0 auto;
    padding: 40px;
}

.paymentFormWrapper {
    align-items: center;
    background: rgba(0,0,0,0.4);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 10;
}

.paymentForm {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    border: 2px solid #dedede;
    border-radius: 10px;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    width: 1059px;
    height: 545px;
    overflow: hidden;
}

.paymentMethodWrapper {
    background: #f2f2f2;
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    justify-content: space-between;
    align-items: center;
    width: 500px;
}

.paymentMethodSelector {
    text-align: center;
    flex: 0 1 auto;
}

.paymentMethodSelector h3 {
    margin-bottom: 20px;
}

.paymentMethodSelectorItem {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: color .1s;
    color: #343f44;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: color .1s;
    white-space: nowrap;
}

.paymentMethodSelectorItem:hover {
    color: #0070c9;
    cursor: pointer;
    text-decoration: underline;
}

.paymentMethodSelectorItem.active {
    color: #0070c9;
    cursor: pointer;
    text-decoration: underline;
}

.upgradeCard {
    position: relative;
    flex: 0 1 auto;
    display: flex;
    align-items: flex-end;
}

.paymentPlanAmount {
    flex: 1;
    justify-content: center;
    display: flex;
    margin-top: 70px;
    text-align: center;
}

.upgradeCard > div > img {
    height: 220px;
    width: auto;
}

.upgradeCard > div > .cardType {
    background: #ffffff;
}

.cardName {
    color: #fff;
    font-family: "Lucida Console", Open Sans, Segoe UI, sans-serif;
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    text-shadow: 0px 2px 3px #000;
    left: 42px;
    bottom: 40px;
}

.cardValidity { 
    color: #fff;
    font-family: "Lucida Console", Open Sans, Segoe UI, sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    text-shadow: 0px 2px 3px #000;
    left: 263px;
    bottom: 65px;
}

.cardNumber {
    color: #fff;
    font-family: "Lucida Console", Open Sans, Segoe UI, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.7px;
    position: absolute;
    text-shadow: 0px 2px 3px #000;
    left: 42px;
    bottom: 90px;
}

.cardCvc {
    font-weight: 400;
    font-family: 'Helvetica', Open Sans, Segoe UI, sans-serif;
    font-size: 7px;
    color: #fff;
    position: absolute;
    left: 42px;
    bottom: 69px;
    text-shadow: none;
}

.cardCvc > span {
    font-size: 4px;
}

.cardCvcNumber {
    color: #fff;
    font-family: "Lucida Console", Open Sans, Segoe UI, sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    text-shadow: 0px 2px 3px #000;
    left: 68px;
    bottom: 65px;
}


.paymentDetailsWrapper {
    flex: 1 0 auto;
    padding: 30px;
    width: 555px;
    display: flex;
    flex-direction: column;
}

.paymentDetailsWrapper input {
    font-weight: 500;
    font-family: 'Camphor Medium', Open Sans, Segoe UI, sans-serif;
    font-size: 15px;
    padding: 11px 20px 10px 20px;
}

.paymentDetailsWrapper h1 {
    font-size: 32px;
    margin-bottom: 50px;
}

.paymentDetailsWrapper h4 {
    margin-bottom: 10px;
}

.paymentDetailsWrapper h5 {
    margin-bottom: 30px;
}

.paymentDetailsWrapper input {
    width: 100%;
    min-width: 420px;
    margin-bottom: 40px;
    border-radius: 4px;
}

.paymentShortDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paymentShortDetails > div {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.paymentShortDetails > div > input {
    min-width: 0;
    width: 100%;
}


.paymentShortDetails > div:first-child {
    margin-right: 20px;
}

.paymentShortDetails > div:last-child {
    margin-left: 20px;
}

.paymentActionWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.creditsPurchase {
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
}


.paymentActionWrapper > div:first-child {
    margin-right: 20px;
    flex: 0;
}

.paymentActionWrapper > div:last-child {
    flex: 1;
}

.paymentActionWrapper > div:last-child > button {
    width: 100%;
}

.paymentActionWrapper > button {
    width: 100%;
}

.upgradePaymentDetails > .paymentActionWrapper {
    align-items: flex-end;
    flex: 1;
}

.upgradePaymentDetails h4 {
    margin-bottom: 30px;
}

.payPalLogoWrapper > div > img {
    width: 220px;
    height: auto;
    margin-bottom: 60px;
}

.payPalAmountSelectorWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
}

.payPalAmountSelectorWrapper > div {
    flex: 1;
}

.amountSelector {
    padding: 10px 20px;
    width: 100px !important;
    height: 40px !important;
}

.cardType {
    position: absolute;
    bottom: 170px;
    right: 22px;
    width: 48px !important;
    height: 30px !important;
    border-radius: 8px;
}

.react-select__value-container {
    height: 40px;
    padding: 0;
    margin: 0;
    caret-color: transparent;
}

.react-select__option--is-selected {
    background: #0070c9; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #0070c9 0%, #42a1ec 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #0070c9 0%,#42a1ec 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #0070c9 0%,#42a1ec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0070c9', endColorstr='#42a1ec',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    color: #ffffff;
}

.reactSelectComponent > .react-select__control--is-focused {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color !important;
    outline-style: auto !important;
    border: 1px solid #dedede !important;
    box-shadow: none;
}

.upgradePaymentDetails .react-select__option {
    text-align: justify;
}

.paymentMethodsWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.paymentMethods {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.paymentMethodsWrapper::-webkit-scrollbar {
    display: none;
}

.paymentMethodBlock {
    flex: 0 0 auto;
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 1080px;
}

.paymentMethodBlock:first-child {
    margin-top: 30px;
}

.paymentMethodBlock:last-child {
    margin-bottom: 30px;
}

.creditCards {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.payPal {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.creditCardWrapper {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
    grid-column-gap: 10px;
    background: #f2f2f2;
    border-radius: 4px;
    padding: 8px 20px;
    margin-bottom: 10px;
    justify-items: center;
    width: 100%;
}

.creditCardWrapper > div:nth-child(3) {
    justify-self: left;
}

.creditCardWrapper > div span {
    font-weight: 600;
    font-family: 'Camphor Bold', Open Sans, Segoe UI, sans-serif;
    font-size: 10px;
    margin-right: 5px;
}

.creditCardWrapper > div {
    display: flex;
    align-items: center;
}

.creditCardWrapper > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.expiredCreditCard span {
    color: #f05230;
}

.creditCardWrapper > div > li {
    font-weight: 400;
    font-family: 'Camphor Regular', Open Sans, Segoe UI, sans-serif;
    font-size: 16px;
}

.creditCardWrapper > div > li:hover {
    color: #0070c9;
    cursor: pointer;
    text-decoration: underline;
}

.creditCardAdditionWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 15px 0 5px 0;
}

.creditCardAdditionWrapper > form {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 15px 30px;
    border: 1px solid #dedede;
    margin-bottom: 5px;
}

.creditCardsNotificationWrapper {
    text-align: center;
}

.cardNumberInputField {
    position: relative;
}

.cardNumberInputField > .InputCardType {
    bottom: 5px;
    right: 5px;
    position: absolute;
    height: 30px;
    width: auto;
}

.creditCardAdditionWrapper > form h4 {
    margin-bottom: 10px;
}

.creditCardAdditionWrapper > form > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;
}

.creditCardAdditionWrapper > form > div:first-child {
    margin-bottom: 20px;
}

.creditCardAdditionWrapper > form > div > div:first-child, .creditCardAdditionWrapper > form > div > div:nth-child(2) {
    margin-right: 20px;
}

.creditCardAdditionWrapper .cardInputFieldLarge {
    flex: 1 0 auto;
}

.creditCardAdditionWrapper .cardInputFieldShort {
    flex: 0 1 auto;
}

.creditCardAdditionWrapper .cardInputFieldLarge > input {
    width: 100%;
}

.creditCardAdditionWrapper .cardInputFieldShort > input {
    width: 100%;
}

.creditCardsAdditionButtonsWrapper {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.creditCardsAdditionButtonsWrapper > .defaultButtonBlue, .creditCardsAdditionButtonsWrapper > .defaultButtonGreen {
    margin-right: 10px;
}

.removePaymentMethodWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.removePaymentMethodWrapper:hover {
    cursor: pointer;
}

.removePaymentMethodWrapper:hover svg > path {
    fill: #000000;
}

.defaultPaymentMethodMarker {
    align-items: center;
    display: flex;
    justify-content: center;
}

.defaultPaymentMethodMarker svg {
    width: 16px;
    height: auto;
    margin-right: 10px;
}

.payPalSelectionWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.payPalSelection {
    flex: 1 1 auto;
    flex-direction: row;
    display: flex;
    align-items: flex-end;
}

.payPalSelection > div:first-child, .payPalSelection > div:nth-child(2) {
    flex: 1;
}

.payPalSelection > div input {
    width: 100%;
}

.payPalSelection > div {
    margin-right: 20px;
}

.currentBalanceChartWrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    background: #dedede;
    border-radius: 4px;
    justify-content: space-between;
    padding: 10px;
}

.currentBalanceChartName {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.currentBalanceChartStatus {
    font-family: "Digital Display", Open Sans, Segoe UI, sans-serif;
    font-size: 42px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}

.currentBalanceChartStatus span {
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-right: 5px;
}

.customAmountInputFieldWrapper {
    position: relative;
}

.customAmountInputFieldWrapper > h6 {
    position: absolute;
    top: -22px;
    left: 0;
    word-break: keep-all;
    white-space: nowrap;
    font-size: 12px;
}

.billingSettingsWrapper, .billingHistoryWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.billingSettings, .billingHistory {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.userAddressData, .invoicesWrapper {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    width: 100%;
}

.invoicesWrapper h1 {
    margin-bottom: 10px;
}


.billingSettingsProfileBlock {
    flex: none;
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 1080px;
    height: 353px;
}

.billingHistoryInvoicesBlock {
    flex: none;
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 1080px;
}

.billingHistoryInvoicesBlock h1 {
    margin-bottom: 15px;
}

.billingSettingsProfileBlock:first-child {
    margin-top: 30px;
}

.billingSettingsProfileBlock:last-child {
    margin-bottom: 30px;
}

.addressWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.addressWrapper input {
    margin-right: 15px;
}

.addressWrapper > div:first-child {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    flex: 1 0 auto;
}

.addressWrapper > div:first-child > div:nth-child(1) {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
}

.addressWrapper > div:first-child > div:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
}

.addressWrapper > div:first-child > div:nth-child(3) {
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
}

.addressWrapper > div:first-child > div:nth-child(4) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
}

.addressWrapper > div:nth-child(2) {
    display: flex;
    flex: 0;
    justify-content: flex-end;
    align-items: flex-end;
}

.addressWrapper > div:nth-child(2) .cancelButton {
    margin-left: 10px;
}


.addressWrapperData {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.addressWrapperData > .address {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addressWrapperData > div:nth-child(2) {
    display: flex;
    flex: 0;
    justify-content: flex-end;
    align-items: flex-end;
}

.address .addressDisplay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 auto;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-size: 18px;
    font-weight: 500;
    height: 100%;
    line-height: 30px;
}

.address .addressDisplay > div {
    background: #f2f2f2;
    border: 1px solid #dedede;
    max-width: 40%;
    padding: 10px 20px;
    border-radius: 4px;
}

.billingAddressEditButtonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.billingAddressEditButtonsWrapper .editIcon {
    display: flex;
    margin-right: 10px;
}

.applyCouponBlock {
    flex: none;
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 1080px;
}

.applyCoupon {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    width: 100%;
}

.applyCouponActionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.applyCouponActionWrapper > input {
    margin-right: 20px;
}




.invoicesHeader {
    background: #dedede;
    display: grid;
    grid-template-columns: 2fr 3fr 12fr 2fr 3fr;
    grid-column-gap: 10px;
    padding: 8px 4px;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.billingEvent {
    display: grid;
    grid-template-columns: 2fr 3fr 12fr 2fr 3fr;
    grid-column-gap: 10px;
    padding: 12px 4px;
    font-size: 14px;
}

.billingEventDescription {
    word-break: keep-all;
    white-space: nowrap;
}

.billingEvent > div { 
    display: flex;
    align-items: center;
}

.billingEventNumber {
    font-family: monospace;
    font-size: 12px;
    letter-spacing: 1.5px;
}

.billingEventAmount, .billingEventAction {
    justify-content: flex-end;
}

.billingEventAction li:hover {
    color: #0070c9;
    cursor: pointer;
    text-decoration: underline;
}

.billingEventAmount {
    font-family: monospace;
}

.billingEventOdd {
    background: rgba(62,176,239,0.1);
}


.referralsWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.referralsStoryWrapper {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    padding: 60px;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    background-color: #f2f2f2;
    border-right: 1px solid #dedede;
}

.referralsStoryWrapper::-webkit-scrollbar {
    display: none;
}

.referralsStory {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 40px 60px;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    max-width: 660px;
}

.referralsStory > div > h1 {
    text-align: center;
    font-size: 28px;
}

.referralsStory > div > h2 {
    font-size: 22px;
    margin: 20px 0 15px 0;
}

.referralsStory > div > h3 {
    text-align: left;
    margin: 0 0 15px 0;
}

.referralsStory > div {
    text-align: center;
}

.referralsStory > div > img {
    padding: 20px;
}

.referralsStory > div > p {
    text-align: justify;
    margin: 10px 0;
}



.referralsStory ul {
    list-style-type: circle;
    list-style-position: outside;
    word-break: normal;
    white-space: normal;
    margin: 20px 40px;
}

.referralsStory ul li {
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-size: 16px;
    list-style-type: disc;
    list-style-position: outside;
    word-break: normal;
    white-space: normal;
    display: list-item;
    text-align: justify;
    margin: 10px 0;
    line-height: 22px;
}

.referralsStory p span {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 14px;
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    color: #FFFFFF;
    padding: 4px 12px;
    border-radius: 12px;
}

.referralsStory hr {
    margin: 30px 0;
    height: 1px;
    border: 0;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left, #ffffff 0%, #dedede 25%, #dedede 75%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffffff 0%, #dedede 25%, #dedede 75%, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffffff 0%, #dedede 25%, #dedede 75%, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}

.referralsStoryLogosDownloadWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    margin: 40px 0 20px 0;
    padding: 0;
    align-items: center;
}

.referralsStoryLogosDownloadWrapper > div:nth-child(2) {
    background: #212425;
}

.referralsStoryLogosDownloadWrapper > div > div > img {
    width: 100%;
    height: auto;
    padding: 8px;
}

.logoDownloadTypeSelectorWrapper {
    padding: 8px 8px 0 8px;
}

.logoDownloadTypeSelectorWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #FFFFFF;
}

.logoDownloadTypeSelectorWrapper > div {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f2f2;
    border-radius: 4px;
    padding: 2px 6px;
    /*margin: 10px 0;*/
}

.referralsStoryStampsDownloadWrapper > div > .logoDownloadTypeSelectorWrapper {
    padding: 8px 0 0 0;
    justify-content: space-between;
}

.referralsStoryStampsDownloadWrapper > div > .logoDownloadTypeSelectorWrapper > div:nth-child(2) {
    margin: 0 8px;
}

.logoDownloadTypeSelectorWrapper > div:hover {
    background: #dedede;
    cursor: pointer;
}

.referralsStoryStampsDownloadWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-column-gap: 30px;
    margin-bottom: 50px;
}

.referralsStoryStampsDownloadWrapper > div > div > img {
    width: 66%;
    height: auto;
    padding: 8px;
}

.referralsStoryProductImagesDownloadWrapper {
    margin-bottom: 50px;
}


.downloadAlllogoAssetsWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.referralsActionAreaWrapper {
    display: flex;
    flex: 1;
    height: 100%;
    padding: 0 30px;
}

.referralsActionAreaWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.referralsActionAreaWrapper::-webkit-scrollbar {
    display: none;
}

.referralsActionAreaBlock {
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    margin: 30px 0;
    padding: 20px;
    width: 100%;
}

.referralsActionAreaBlock:first-child {
    margin-top: 30px;
}

.referralsActionAreaBlock:last-child {
    margin-bottom: 30px;
}

.referByEmailWrapper, .shareLinkWrapper, .referralStatsWrapper, .payoutDetailsWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.referByEmail, .shareLink, .referralStats, .payoutDetails {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    text-align: center;
}

.referByEmail h4, .referralStats h4, .payoutDetails h4 {
    margin-bottom: 20px;
    max-width: 90%;
}

.shareLink h4 {
    margin-bottom: 30px;
    max-width: 90%;
}

.shareLinkAction {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.shareLinkAction > div > a {
    border-width: 1px;
    border-style: solid;
    color: #ffffff;
    display: inline-block;
    font-family: inherit;
    height: 32px;
    line-height: 32px;
    outline: none;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    padding: 0 15px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.025em;
}

.shareLinkAction > div > a:hover {
    text-decoration: none;
    cursor: pointer;
}

.shareLinkAction > div > a:focus {
    outline: none;
}

.shareLinkAction > div > a:focus[data-focus-method="mouse"]:not(input):not(textarea):not(select),
.shareLinkAction > div > a:focus[data-focus-method="touch"]:not(input):not(textarea):not(select) {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.shareLinkAction > div > a:active {
    outline: none;
}

.shareLinkToken {
    background: #f2f2f2;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 8px 0;
    flex: 0 1 auto;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-right: 20px;
}

.shareLinkToken:hover {
    cursor: pointer;
}

.shareLinkToken .tokenKey {
    font-family: monospace;
    -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

}

.referralStatsTable {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.referralStatsTable > div {
    flex: 1 0 auto;
    display: grid;
    grid-template-columns: 2fr 13fr 2fr;
    grid-column-gap: 10px;
    width: 100%;
    padding: 12px 4px;
    font-size: 14px;
}

.referralStatsTable > div > div {
    display: flex;
    align-items: center;
}

.referralStatsTable >div:nth-child(odd) {
    background: rgba(62,176,239,0.1);
}

.referralStatsAmount {
    font-family: monospace;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 600;
}

.referralStat {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
}

.referralStatsDescription {
    font-size: 13px;
}

.payPalAccountAddressWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.payPalAccountAddressWrapper > div:first-child {
    flex: 1 0 auto;
    margin-right: 20px;
} 

.payPalAccountAddressWrapper > div > input {
    width: 100%;
}

.payPalAccountAddressWrapper .cancelButton {
    margin-left: 20px;
}

.payPalPaymentEmailAddress {
    background: #f2f2f2;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 11px 0;
    flex: 0 1 auto;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 15px;
    width: 100%;
    text-align: center;
}

.payPalAccountAddressWrapper .editIcon {
    margin-right: 20px;
}

.referralEmailInvite {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 3% 4%;
    width: 100%;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: center;
    position: relative;
}

.referralEmailInvite > div > h1 {
    font-size: 30px;
}

.referralEmailInvite > div > h4 {
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-size: 18px;
}

.referralEmailInvite > div > h4 > span {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    line-height: 26px;
}

.inviteGmailContactsButtonWrapper {
    margin: 30px 0;
}

.inviteGmailContactsButton {
    display: flex;
    flex-direction: row;
    background: #f2f2f2;
    border-radius: 4px;
    align-items: center;
    padding: 4px 20px;
    height: auto;
    color: #343f44;
}

.inviteGmailContactsButton:hover {
    background: #dedede;
}

.inviteGmailContactsButton > svg {
    margin-right: 10px;
}

.referralEmailInvite > div:nth-child(2) {
    width: 100%;
    text-align: center;
}

.referralEmailInvite textarea {
    resize: none;
    width: 100%;
    height: 300px;
    font-size: 16px;
    line-height: 24px !important;
    margin: 0;
    padding: 4px 8px 20px 8px;
    border: 1px solid #dedede;
    border-radius: 4px;
}

.textAreaHighlight {
    background: rgba(164,208,55,0.2);
    resize: none;
    width: 300px;
    height: 300px;
    font-size: 16px;
    color: #343f44;
    margin: 0;
    padding: 0;
}

.highlightContainerWrapper {
    width: 100%;
    border-radius: 4px;
}

.heighlightTextAreaNotification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.excedeedNumberOfMessages {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 12px;
    opacity: 0.4;
    text-align: left;
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0 8px;
    color: #F05230;
}
.availableInvitesCounter {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 12px;
    opacity: 0.4;
    text-align: right;
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0 8px;
}

.highlightContainer {
    line-height: 24px !important;
    width: 100%;
    border-radius: 4px;
    padding: 8px;
}

.highlightContainer > div {
    padding: 12px 16px 50px 16px;
    border-radius: 4px;
}

.inviteReferralEmailsActionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    width: 100%;
}
.inviteReferralEmailsActionWrapper > .cancelButton {
    margin-left: 10px;
}

.defaultButtonBlue:disabled {
    -webkit-opacity:0.3; /* Override iOS opacity change affecting text & background color */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    pointer-events:none; 
    opacity:0.5;  
}










/* Palette Colors */
.colorPalette {
    display: flex;
    flex-direction: column;
}
.colorPaletteMainColors {
    display: flex;
    flex-direction: column;
}
.colorPaletteMainColors > div {
    display: grid;
    grid-template-columns: 10fr 9fr;
    grid-column-gap: 10px;
    margin: 10px 0;
}
.colorPaletteMainColors > div > div {
    min-height: 50px;
}
.colorPaletteMainColors > div > .colorDescription {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
}
.colorPaletteGrayscale {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
}
.colorPaletteGrayscale > div {
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 10px 0 0 0;
}
.colorPaletteGrayscale > div > div {
    min-height: 50px;
}
.colorPaletteGrayscale > div > .colorDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 20px;
    text-align: left;
}

.colorDescription > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: monospace;
    font-size: 13px;
    font-weight: 600;
}
.colorDescription > div > span {
    -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.colorDescription > div:first-child {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 600;
}
.brightNavyBlue {
    background-color: #0070C9;
}
.yellowGreen {
    background-color: #A4D037;
}
.sunglow {
    background-color: #FECD35;
}
.portlandOrange {
    background-color: #F05230;
}
.earieBlack {
    background-color: #212425;
}
.outerSpaceCrayola {
    background-color: #343F44;
}
.silverSand {
    background-color: #C0C0C0;
}
.gainsboro {
    background-color: #DEDEDE;
}
.cultured {
    background-color: #F2F2F2;
}


.appTasksWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.taskFilterWrapper {
    background: #ffffff;
    flex: 0 1 auto;
    border-bottom: 1px solid #dedede;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;

      -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;  
    -ms-user-select: none;
    user-select: none; 
    padding: 8px 20px;
    background: #f2f2f2;
}

.taskFilter {
    flex: 0;
}

.taskFilter input[type="search"] {
    border: 1px solid #dedede;
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    padding: 4px 8px;
    width: 200px;
}

.pagination > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination > div > span > .prevPage, .pagination > div > span > .nextPage {
    height: 10px;
    margin: 0 8px;
}

.pagination > div > span > .prevPage:hover, .pagination > div > span > .nextPage:hover {
    cursor: pointer;
    fill: #0070C9;
}

.numberOfResultsSelector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.numberOfResultsSelector > div:first-child {
    margin-right: 8px;
}

.numberOfResultsSelector > div > ul > li {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding: 4px 8px;
    border-radius: 10px;
    margin: 0 2px;
}

.numberOfResultsSelector > div > ul > li:hover {
    background: #0070c9; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #0070c9 0%, #42a1ec 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #0070c9 0%,#42a1ec 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #0070c9 0%,#42a1ec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0070c9', endColorstr='#42a1ec',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    color: #ffffff;
    cursor: pointer;
}

.numberOfResultsSelector > div > ul > .selectedNumber {
    background: #0070c9; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #0070c9 0%, #42a1ec 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #0070c9 0%,#42a1ec 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #0070c9 0%,#42a1ec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0070c9', endColorstr='#42a1ec',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    color: #ffffff;
}


.tasksWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.tasksWrapper::-webkit-scrollbar {
    display: none;
}

.tasksWrapper > div {
    flex-direction: column;
    flex: 1;
    padding: 15px;
}

.tasksWrapper > div > .taskWrapper:last-child {
    margin-bottom: 0;
}

.taskWrapper {
    border: 1px dashed transparent;
    border-radius: 4px;
    margin-bottom: 10px;
}

.taskWrapper:hover {
    border: 1px dotted rgba(62,176,239,1);
    cursor: pointer;

}

.taskWrapper .task {
    padding: 15px;
    transition: all 0.3s ease-out;
}

.taskWrapper .taskLookup {
    padding: 10px 15px;
    transition: all 0.3s ease-out;
}

.taskWrapper.inFocus {
    border: 1px dotted rgba(62,176,239,1);
    background: none;
}

.taskWrapper.inFocus > .task, .taskWrapper.inFocus > .taskLookup {
    background: rgba(62,176,239,0.1);
}

.taskLookup > .contactAvatar img {
    width: 32px;
    height: auto;
}


.task {
    display: grid;
    grid-template-columns: 1fr 10fr 3fr 2fr 5fr 5fr 7fr 5fr;
    grid-column-gap: 10px;
}

.taskLookup {
    display: grid;
    grid-template-columns: 1fr 6fr 6fr 3fr 1fr 12fr 4fr;
    grid-column-gap: 10px;
}

/*.taskLookup > div:nth-child(even) {
    background: red;
}
.taskLookup > div:nth-child(odd) {
    background: green;
}*/

.task > div, .taskLookup > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.task > div .checkCircle, .taskLookup > div .checkCircle {
    width: 16px;
    height: auto;
}

.task > div .timesCircle, .taskLookup > div .timesCircle {
    width: 16px;
    height: auto;
}

.taskDomainName, .taskEventTime, .taskFoundAddresses, .taskSuggestedLookups, .taskAlternativeDomains {
    /*font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 14px;*/
    font-family: monospace;
    font-size: 12px;
    word-break: keep-all;
    white-space: nowrap;
}

.taskFoundAddresses, .taskSuggestedLookups, .taskAlternativeDomains {
    border-radius: 12px;
    padding: 4px 12px;

    background: #dedede;
}

.taskFoundAddresses span, .taskSuggestedLookups span, .taskAlternativeDomains span {
    font-family: monospace;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.taskStatus > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.processingBarWrapper { 
  flex: 1;
  position:relative;
  padding:1px;
}
.processingBarWrapper:before {
  content: '';
  border: 1px solid transparent; 
  position:absolute;
  top:-2px; 
  right:0; 
  bottom:-2px; 
  left:0;
}
.processingBarWrapper .processingBar { 
  position:absolute;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background: #A4D037; 
  width:0;
}

.processingBar0 {
    animation: borealisBar 1.1s linear infinite;
}
.processingBar1 {
    animation: borealisBar 1.3s linear infinite;
}
.processingBar2 {
    animation: borealisBar 1.5s linear infinite;
}
.processingBar3 {
    animation: borealisBar 1.7s linear infinite;
}
.processingBar4 {
    animation: borealisBar 1.9s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}

.ReactCollapse--collapse {
  transition: height 0.3s ease-out;
}





.reactTooltip {
    border-radius: 4px !important;
    color: #fff !important;
    background-color: #212425 !important;
    cursor: pointer !important;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px;
    opacity: 0.95 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.reactTooltipHidden {
    display: none !important;
}

.reactTooltip > .toolTipDomainSearchChartContent {
    display: flex;
    flex-direction: column;
}

.toolTipDomainSearchChartContent > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.toolTipDomainSearchChartContent > div > div {
    display: flex;
    justify-content: flex-center;
    align-items: center;
    flex: 0;
    margin: 1px 0;
}

.toolTipDomainSearchChartContent > div > div:nth-child(2) {
    padding: 0 10px;
    display: flex;
    flex: 1;
    word-break: keep-all;
    white-space: nowrap;
}

.filteredEmailsList {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.taskContent {
    display: flex;
    flex-direction: row;
    padding: 4px 0;
}

.taskContent-prospects {
    background: #a4d037;
}
.taskContent-generic {
    background: #3eb0ef;   
}
.taskContent-unknown {
    background: #fecd35;
}
.taskContent-relevant {
    background: #f05230;
}
.taskContent-fraudulent {
    background: #ad26b4;
}

.verificationEmailType-prospect {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 20px;
    background: #a4d037;
    text-transform: uppercase;
}

.verificationEmailType-generic {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 20px;
    background: #3eb0ef;
    text-transform: uppercase;
}

.verificationEmailType-unknown {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 20px;
    background: #fecd35;
    text-transform: uppercase;
}
.verificationEmailType-fraudulent {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 20px;
    background: #ad26b4;
    text-transform: uppercase;
}

.verificationEmailTarget-regular {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    padding: 4px 8px;
    border-radius: 20px;
    background: #dedede;
}

.verificationEmailTarget-free {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    padding: 4px 8px;
    border-radius: 20px;
    background: #dedede;
}

.verificationEmailTarget-disposable {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    padding: 4px 8px;
    border-radius: 20px;
    background: #dedede;
}

.taskContent > div:first-child {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 4px 4px 0;
}

.taskContent > div:first-child h3 {
    justify-content: flex-start;
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding: 8px 4px;
    color: #ffffff;
    letter-spacing: 1px;
}

.domainSearchEmailWrapper {
    display: grid;
    grid-template-columns: 1fr 6fr 1fr 4fr 6fr 2fr 3fr;
    grid-column-gap: 5px;
    padding: 4px 0;
}

.domainSearchEmailWrapper:hover {
    background: rgba(62,176,239,0.08);
}

.domainSearchEmailWrapper > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    word-break: keep-all;
    white-space: nowrap;
}

.domainSearchEmailWrapper > .contactAvatar img {
    width: 24px;
    height: auto;
}

.domainSearchEmailWrapper > .foundEmailAddress {
    justify-content: flex-start;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 15px;
}

.domainSearchEmailWrapper > .foundEmailAddress > a {
    color: #343f44;
}

.domainSearchEmailWrapper > .foundEmailAddress > a:hover {
    text-decoration: underline;
    color: #0070c9;
}

.taskLookup > .foundEmailAddress {
    /*justify-content: flex-start;*/
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 15px;
}

.taskLookup > .foundEmailAddress > a {
    color: #343f44;
}

.taskLookup > .foundEmailAddress > a:hover {
    text-decoration: underline;
    color: #0070c9;
}

.domainSearchEmailWrapper > .contactName, .domainSearchEmailWrapper > .contactPosition  {
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 15px;
}

.domainSearchEmailWrapper > .verificationStatusPass, .domainSearchEmailWrapper > .verificationStatusFail, .taskLookup > .taskStatus > .verificationStatusPass, .taskLookup > .taskStatus > .verificationStatusFail {
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-size: 13px;   
}

.domainSearchEmailWrapper > .verificationStatusPass, .taskLookup > .taskStatus > .verificationStatusPass {
    /*color: #a4d037;*/
}

.domainSearchEmailWrapper > .verificationStatusFail, .taskLookup > .taskStatus > .verificationStatusFail {
    /*color: #f05230;*/
}

.linkedInLogo {
    
    padding-top: 3px;
    width: 60px;
    height: auto;
}

.linkedInLogo:hover > g {
    fill: #0a66c2;
}

.discoverdEmailsTitle {
    padding: 15px 0 0 30px;
}

.alternativeDomainsWrapper, .suggestedLookupsWrapper {
    padding: 10px 15px;
}
.alternativeDomainsWrapper > div {
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px dotted rgba(62,176,239,1);
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 15px;
}

.suggestedLookupsWrapper > div {
    padding: 10px 15px;
    word-break: keep-all;
    white-space: nowrap;
    border-radius: 4px;
    border: 1px dotted rgba(62,176,239,1);
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 15px;
}

.alternativeDomainsWrapper img {
    margin-right: 20px;
}

.alternativeDomainsWrapper span {
    margin-left: 20px;
    color: #0a66c2;
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.alternativeDomainsWrapper span:hover {
    text-decoration: underline;
}

.alternativeDomainsList {
    display: grid;
    grid-template-columns: 1fr 5fr 20fr;
    grid-column-gap: 10px;
}

.alternativeDomainsList > div {
    display: flex;
    align-items: center;
}

.suggestedLookupsList {
    display: grid;
    grid-template-columns: 1fr 4fr 6fr 1fr;
    grid-column-gap: 10px;
}

.suggestedLookupsList span {
    color: #0a66c2;
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.suggestedLookupsList span:hover {
    text-decoration: underline;
}

.suggestedLookupsWrapper > div > div:nth-child(even) {
    background: #f2f2f2;
}

.suggestedLookupsList > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px;
}

.taskWrapper h1 {
    margin: 0;
    padding-bottom: 15px;
}

.failedLookupTitle > h1 {
    padding: 15px;
}


.tagsHelperPanelWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    max-height: 100%;
    height: 100%;
}

.tagsHelperPanelWrapper > div {
    flex: 1;
}

.tagsHelperPanelWrapper > div:nth-child(2) {
    width: 100%;
    margin: 20px 0 10px 0;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    display: grid;
}

.tagsHelperPanelWrapper > div:nth-child(2)::-webkit-scrollbar {
    display: none;
}

.tagsHelperPanelWrapper > .tagsTitle {
    align-self: flex-start;
    flex: 0;
}

.tagsHelperPanelWrapper .tagsList {
    display: flex;
}

.tagsHelperPanelWrapper .tagsList > div {
    border: 1px solid #dedede;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background: repeating-linear-gradient(
      #f2f2f2,
      #f2f2f2 20px,
      #ffffff 20px,
      #ffffff 40px
    );
    flex: 1;
}

.tagsHelperPanelWrapper .tagsList > div > div {
    flex: 0 0 auto;
}

.tagsHelperPanelWrapper .tagsAction {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    flex: 0;
    padding-bottom: 5px;
}

.tagsHelperPanelWrapper > div > button {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 24px;
    width: 30px;
}

.tagsHelperPanelWrapper > div > button:first-child {
    border-radius: 4px 0  0 4px;
}

.tagsHelperPanelWrapper > div > button:last-child {
    border-radius: 0 4px 4px 0;
}

.tagsHelperPanelWrapper > div > button > svg {
    width: 12px; 
    height: auto;
    fill: #343F44;
}

.tagLabelWrapper {
    flex: 0;
    flex-direction: row;
    display: flex;
    padding: 1px 0;
}

.tagLabelColor {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tagLabelColor svg {
    margin: 0 12px;
}

.tagLabel {
    flex: 1;
}

.tagLabel > form > input {
    padding: 0;
    margin: 0;
    width: 100%;
    background: none;
    border: none;
    font-family: Camphor Regular, Open Sans, Segoe UI, sans-serif;
    font-weight: 400;
    font-size: 13px;
    outline: none;
}

.tagLabel > form > input:focus {
    outline:none; 
}

.tagLabelWrapper:hover {
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    color: #FFFFFF;
    cursor: pointer;
}

.tagLabelWrapper:hover > .tagLabel > form > input {
    color: #ffffff;
    cursor: pointer;
}

.labelWrapperInFocus {
    cursor: text;
}

.tagLabelWrapper.labelWrapperInFocus:hover {
    background: none;
    cursor: pointer;
}

.tagLabelWrapper.labelWrapperInFocus:hover > .tagLabel > form > input {
    color: #000000;
    cursor: text;
}

.labelMarked {
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    cursor: pointer;
}

.tagLabelWrapper.labelMarked form input {
    color: #FFFFFF;
}

.colorPickerWrapper {
    position: absolute;
    display: none;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;

    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
}

.colorPicker {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    padding: 20px;
}

.colorPickerColor > svg:hover {
    cursor: pointer;
}

.contactTags {
    justify-content: flex-start !important;
}

.tags {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 6px;
}

.tags > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.assign > g > path:nth-child(1) {
    opacity: 0;
}

.assign:hover > g > path:nth-child(1) {
    opacity: 1;
    fill: #dedede;
}



.tagSelectorWrapper, .tagRemovalSelectorWrapper, .taskRemovalSelectorWrapper {
    position: absolute;
    display: none;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;

    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);

}

.tagSelector {
    max-height: 200px;
    overflow: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    padding: 20px 0;
}

.tagSelector::-webkit-scrollbar {
    display: none;
}

.tagSelector > div {
    display: flex;
    flex-direction: column;
}

.tagSelector > div > div, .tagSelector > div > div > div {
    display: flex;
    align-items: center;
}

.tagSelector > div > div {
    padding: 4px 20px;
}

.tagSelector > div > div:hover {
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    cursor: pointer;
    color: #ffffff;
}

.tagSelectorTag {
    display: flex;
    flex-direction: row;
}

.tagSelectorTag svg {
    margin-right: 12px;
}

.tagRemovalSelector, .taskRemovalSelectorWrapper {
    padding: 0;
    border-radius: 4px;
}

.tagRemovalSelector > div, .taskRemovalSelectorWrapper > div {
    padding: 4px 20px;
    border-radius: 4px;
}

.tagRemovalSelector > div:hover, .taskRemovalSelectorWrapper > div:hover {
    background-color: #0070c9;
    background: -webkit-gradient(linear, left top, left bottom, from(#42a1ec), to(#0070c9));
    background: -webkit-linear-gradient(#42a1ec, #0070c9);
    background: linear-gradient(#42a1ec, #0070c9);
    cursor: pointer;
    color: #ffffff;
}

.contactListsFilterWrapper {
    background: #f2f2f2;
    border-bottom: 1px solid #dedede;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    position: relative;
}

.contactListsFilterWrapper > div > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tasksWrapper > div > .contactItemWrapper:nth-child(odd) {
    background: rgba(62,176,239,0.08);
}
.tasksWrapper > div > .contactItemWrapper:nth-child(even) {
    background: rgba(254,205,53,0.08);
}

.contactItem {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 4fr 5fr;
    padding: 4px 8px;
}

.contactItem .timesCircle, .contactItem .checkCircle {
    width: 16px;
    height: 16px;
}

.contactItemWrapper > .contactItem > .contactAvatar .gravatarImage {
    width: 26px;
    height: 26px;
}

.contactItem > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contactsTagSelectorWrapper {
    position: absolute;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;

    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2),
        -10px 0 20px 0px rgba(0, 0, 0, 0.2);

    top: 54px;
    left: 20px;
}

.contactListsFilterWrapper > div > .tagSelectorTag:hover {
    cursor: pointer;
}






.footer {
    flex: 0 0 auto;
    width: 100%;
    word-break: keep-all;
    white-space: nowrap;
}

.creditsChartBlock {
    background: #f2f2f2;
    border-top: 1px solid #dedede;
    padding: 10px 40px;
    width: 100%;
}

.creditsCharts {
    display: grid;
    grid-template-columns: 6fr 6fr 6fr 6fr 6fr 1fr;
    grid-column-gap: 10px;
}

.creditsCharts h1 {
    font-size: 30px;
    margin-bottom: 12px;
}

.creditsChartsTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.creditsChartsTitle > h1 {
    margin-top: 6px;
}

.downgradeAlert {
    color: #f05230;
}

.creditsChartDowngradeAlertCancelWrapper {
    display: flex;
    flex-direction: row;
}

.creditsChartDowngradeAlertCancelWrapper li:hover {
    color: #0070c9;
    cursor: pointer;
    text-decoration: underline;
}

.lookupCreditsChartWrapper, .verificationCreditsChartWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lookupCreditsChart {
    height: auto;
    margin-top: 8px;
    width: 160px;
}

.verificationCreditsChart {
    height: auto;
    margin-top: 8px;
    width: 160px;
}

.permanentCreditsChartWrapper {
    align-items: center;
    display: flex;
    background: #dedede;
    border-radius: 4px;
    justify-content: space-between;
    padding: 10px;
}

.creditsCharts .chartStatus {
    font-family: Camphor Heavy, Open Sans, Segoe UI, sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.creditsCharts .chartName {
    font-family: Camphor Bold, Open Sans, Segoe UI, sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.permanentCreditsChartStatus {
    font-family: "Digital Display", Open Sans, Segoe UI, sans-serif;
    font-size: 60px;
    line-height: 60px;
}

.permanentCreditsChartWrapper .chartName {
    align-self: flex-start;
}

.kIdentificator {
    font-family: Camphor Heavy, Open Sans, Segoe UI, sans-serif;
    font-size: 20px;
    font-weight: 700;
    vertical-align: text-top;
    line-height: 20px;
}

.upgradeAccountButtonWrapper, .crispChatIconButtonWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
}

.crispChatIcon:hover {
    cursor: pointer;
}

.crispChatIcon:hover .stop1 {
    stop-color: #51a9ee;        
}

.crispChatIcon:hover .stop2 {
    stop-color: #147bcd;
}

.creditCardIcon {
    fill: #343f44;
    height: 24px;
    width: auto;
}