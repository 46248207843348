.notification-container {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999999;
    max-width: 400px;
    width: 95%;
    padding: 0;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
}

.notification {
    box-sizing: border-box;
    padding: 15px 15px 15px 58px;
    border-radius: 4px;
    color: #fff;
    background-color: #ccc;
    box-shadow: 0 0 12px #999;
    cursor: pointer;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    opacity: 0.95;
    margin-bottom: 15px;
    margin-right: 15px;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-family: Camphor Medium, Open Sans, Segoe UI, sans-serif;
    font-weight: 600;
    margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
}

.notification-leave {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
}

.notification:before {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -14px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 28px;
    text-align: center;
    line-height: 28px;
}

.notification-info {
    background-color: #212425;
}

.notification-info:before {
    content: url("../assets/img/info-circle.svg");
}

.notification-success {
    background-color: #212425;
}

.notification-success:before {
    content: url("../assets/img/check-circle.svg");
}

.notification-warning {
    background-color: #212425;
}

.notification-warning:before {
    content: url("../assets/img/exclamation-circle.svg");
}

.notification-error {
    background-color: #212425;
}

.notification-error:before {
    content: url("../assets/img/times-circle.svg");
}
